'use client';

import Link from 'next/link';
import { Button, ButtonType, ButtonVariant, Input } from '@maestro/catarata';
import { PagesUrls } from '@/app/truco/utils/pageUrls';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { apiFetch } from '@maestro/catarata';
import AccessContainer from '../../components/molecules/AccessContainer';

export default function Login() {
  const [errors, setErrors] = useState<string[]>([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const mutation = useMutation(
    (data: { email: string; password: string }) => {
      setErrors([]);
      return apiFetch<{ message: string }>('/api/login', {
        method: 'POST',
        body: data,
      });
    },
    {
      onSuccess: (data) => {
        console.log('Success', data);
        if (data.message && data.message !== 'ok') {
          setErrors([data.message]);
        } else {
          location.href = '/loggedCallback';
        }
      },
      onError: (data: { response?: { message: string } }) => {
        setErrors([
          data.response?.message! || 'Error de conexión. Intente nuevamente.',
        ]);
      },
    },
  );
  return (
    <AccessContainer>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          mutation.mutate({ email, password });
        }}
        className="flex flex-col gap-4"
        action="/"
        method="post"
        id="frmLogin"
      >
        <h3 className="text-2xl text-primary-on font-bold">
          Jugá con tu e-mail
        </h3>
        <Input
          type="email"
          name="email"
          placeholder="Tu e-mail"
          hasError={errors.length > 0}
          errorFeedback={errors.join(', ')}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          name="password"
          placeholder="Tu contraseña"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="flex flex-row-reverse items-center gap-4">
          <Button
            variant={ButtonVariant.PRIMARY}
            type={ButtonType.SUBMIT}
            label="Ingresar"
            className="w-full max-w-[200px]"
          />
          <Link
            className="md:hover:underline"
            href={PagesUrls.HOME}
            title="Cancelar"
          >
            Cancelar
          </Link>
        </div>
        <hr />
        <p>
          <span>¿Olvidaste tu contraseña?</span>
          <Link
            href={PagesUrls.FORGOT_PASSWORD}
            className="text-primary md:hover:underline font-bold block"
          >
            Recuperar contraseña.
          </Link>
        </p>
      </form>
    </AccessContainer>
  );
}
