import Image from 'next/image';

export default function AccessContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <section className="flex flex-col min-h-screen items-center justify-center py-2">
      <section className="flex flex-col items-center">
        <Image
          src="/img/newdesign/v2/home/floating-header.png"
          alt=""
          width={468}
          height={196}
          aria-hidden="true"
          className="-mb-12 relative z-10"
        />
        <section className="flex flex-col p-10 text-white bg-surface-container-lowest rounded-3xl max-w-[350px] w-full">
          {children}
        </section>
      </section>
    </section>
  );
}
